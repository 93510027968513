import React from "react";
import axios from 'axios';
import "./PageStyles.css";

const ContactSupport = () => {
  class EmailForm extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        email: '',
        subject: '',
        body: '',
        message: '',
      };
  
      this.handleChange = this.handleChange.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
    }
  
    handleChange(event) {
      const { name, value } = event.target;
      this.setState({
        [name]: value
      });
    }
  
    async handleSubmit(event) {
      event.preventDefault();
      const { email, subject, body } = this.state;
  
      const mailgunApiKey = '763bf03d9f28e8cb216f810e8eae1c94-51356527-cafb0651';
      const mailgunDomain = 'mail.rated10.app';
      const mailgunUrl = `https://api.mailgun.net/v3/${mailgunDomain}/messages`;
  
      const data = new URLSearchParams({
        from: email,
        to: 'mert@rated10.app',
        subject: subject,
        text: body
      });
  
      try {
        await axios.post(mailgunUrl, data, {
          auth: {
            username: 'api',
            password: mailgunApiKey
          },
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        });
        this.setState({ message: 'Email sent successfully!'});
      } catch (error) {
        console.error('Error sending email:', error);
        this.setState({ message: 'Failed to send email. Please try again later.' });
      }
    }
  
    render() {
      const { email, subject, body, message } = this.state;
  
      const containerStyle = {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '10vh',
        color: '#E35335',
        textAlign: 'center',
        padding: '10px 0',
        boxSizing: 'border-box'
      };

      const formStyle = {
        width: '100%',
        maxWidth: '500px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '10px',
        borderRadius: '8px',
        margin: '10px 0'
      };
  
      const inputStyle = {
        margin: '5px 0',
        padding: '10px',
        width: '100%',
        maxWidth: '500px'
      };
  
      const buttonStyle = {
        margin: '10px 0',
        padding: '10px 20px',
        backgroundColor: '#E35335',
        color: '#fff',
        border: 'none',
        cursor: 'pointer'
      };
  
      return (
        <div style={containerStyle}>
          <form onSubmit={this.handleSubmit} style={formStyle}>
            <input
              type="email"
              name="email"
              placeholder="Your Email"
              value={email}
              onChange={this.handleChange}
              required
              style={inputStyle}
            />
            <input
              type="text"
              name="subject"
              placeholder="Subject"
              value={subject}
              onChange={this.handleChange}
              required
              style={inputStyle}
            />
            <textarea
              name="body"
              placeholder="Text Body"
              value={body}
              onChange={this.handleChange}
              required
              rows="5"
              style={{ ...inputStyle, height: '150px' }}
            />
            <button type="submit" style={buttonStyle}>Submit</button>
          </form>
          {message && <p>{message}</p>}
        </div>
      );
    }
  }

  return (
    <div className="one-screen d-flex flex-column justify-content-between text-r10_orange text-center" style={{ padding: '10px', boxSizing: 'border-box', minHeight: '10vh', overflowY: 'auto' }}>
      <EmailForm />
      <div className="mb-5 fs-4" style={{ margin: '10px 0 0 0' }}>
        Alternatively, please send an email to support@rated10.app
      </div>
    </div>
  );
};

export default ContactSupport;
