import React, { Component } from 'react';

class R10FooterLink extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isHovered: false,
      isFocused: false,
    };

    this.handleMouseEnter = this.handleMouseEnter.bind(this);
    this.handleMouseLeave = this.handleMouseLeave.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
  }

  handleMouseEnter() {
    this.setState({ isHovered: true });
  }

  handleMouseLeave() {
    this.setState({ isHovered: false });
  }

  handleFocus() {
    this.setState({ isFocused: true });
  }

  handleBlur() {
    this.setState({ isFocused: false });
  }

  render() {
    const { isHovered, isFocused } = this.state; // disabled isFocused, maybe make font bold instead of color change
    const { href, children } = this.props;

    const linkStyle = {
      color: isHovered ? '#E35335' : '#FFFFFF',
      textDecoration: 'none',
      cursor: 'pointer',
    };

    const isExternal = !href.startsWith("https://www.rated10.app");

    return (
      <a
        href={href}
        style={linkStyle}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
        onFocus={this.handleFocus}
        onBlur={this.handleBlur}
        target={isExternal ? "_blank" : "_self"} // Opens the link in a new tab
        rel={isExternal ? "noopener noreferrer" : undefined} // Security measure for external links
      >
        {children}
      </a>
    );
  }
}

export default R10FooterLink;