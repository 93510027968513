import { useEffect, useState } from "react";
import { auth, fbTimestamp, fbUI, fbUIAuth } from "../utilities/Firebase";
import { OAuthProvider, signInWithPopup } from "firebase/auth";
import { useLocation, useNavigate } from "react-router-dom";
import "firebaseui/dist/firebaseui.css";
import "./PageStyles.css";
import { createDoc, loading } from "../utilities/Functions";
import { AuthenticateWithTwitch } from "../utilities/TwitchLoginFunctions";

import Icon_twitch from "../assets/Icon_twitch.svg";
import Icon_apple_black from "../assets/Icon_apple_black.svg";

const Login = () => {
  const location = useLocation();

  const [status, setStatus] = useState();

  const [pending, setPending] = useState();
  const [error, setError] = useState();

  const [updatePending, setUpdatePending] = useState();

  const navigate = useNavigate();

  var navigateFlag = false;

  var uiConfig = {
    callbacks: {
      signInSuccessWithAuthResult: signInSuccess, //function (authResult, redirectUrl) {
      // User successfully signed in.
      // Return type determines whether we continue the redirect automatically
      // or whether we leave that to developer to handle.
      // return true;
      // },
      // uiShown: function () {
      // // The widget is rendered.
      // // Hide the loader.
      // document.getElementById("loader").style.display = "none";
      // },
    },
    // Will use popup for IDP Providers sign-in flow instead of the default, redirect.
    signInFlow: "popup",
    // signInSuccessUrl: "<url-to-redirect-to-on-success>",
    signInOptions: [
      // Leave the lines as is for the providers you want to offer your users.
      fbUIAuth.GoogleAuthProvider.PROVIDER_ID,
      // fbUIAuth.FacebookAuthProvider.PROVIDER_ID,
      // fbUIAuth.TwitterAuthProvider.PROVIDER_ID,
      // fbUIAuth.GithubAuthProvider.PROVIDER_ID,
      fbUIAuth.EmailAuthProvider.PROVIDER_ID,
      // fbUIAuth.PhoneAuthProvider.PROVIDER_ID,
      // AppleAuthProvider.PROVIDER_ID,

      // fbUIAuth.TwitchAuthProvider.PROVIDER_ID,
    ],
    // // Terms of service url.
    // tosUrl: "<your-tos-url>",
    // // Privacy policy url.
    // privacyPolicyUrl: "<your-privacy-policy-url>",
  };

  // signed in
  useEffect(() => {
    if (auth.currentUser && !navigateFlag) {
      navigateFlag = true;
      navigate(location.state && location.state.fromInside ? -1 : "/", {
        state: location.state,
      });
    }
  }, [auth]);

  // firebase login ui
  useEffect(() => {
    let d = document.getElementById("firebaseui-auth-container");
    d.addEventListener("DOMNodeInserted", () => {
      let elements = document.querySelectorAll(".firebaseui-idp-button");
      elements.forEach((element) => {
        element.style.minHeight = "60px";
        element.style.maxHeight = "20px";
        element.style.minWidth = "110px";
        element.style.maxWidth = "330px";
      });
    });

    fbUI.start("#firebaseui-auth-container", uiConfig);
  }, []);

  // signed up
  useEffect(() => {
    if (pending && !updatePending) {
      if (error) {
        // error handling - signed up but user record cannot be created in storage
      } else {
        // setPending(false);
        sessionStorage.setItem("token", JSON.stringify(auth.currentUser));
        // navigate("/", { state: location.state });
        if (!navigateFlag) {
          navigateFlag = true;
          navigate(location.state && location.state.fromInside ? -1 : "/", {
            state: location.state,
          });
        }
      }
    }
  }, [updatePending]);

  // ****************************************
  // rest is function calls and return render
  // ****************************************

  function signInSuccess(authResult, redirectUrl) {
    // setPending(true);
    // console.log(authResult);
    if (
      authResult &&
      authResult.additionalUserInfo &&
      authResult.additionalUserInfo.isNewUser
    ) {
      // signup - create a record in user table

      let docId = authResult.user.uid;
      let userData = {
        email: authResult.user.email,
        name: authResult.user.displayName,
        environment: "prod",
        lastLoginDate: fbTimestamp.fromDate(new Date()),

        reviewSubmissions: null,
        oneQuestionSubmissions: null,
        multiplePhotosSubmissions: null,
        asCreator: null,
        eligibleToBeCreator: true,
        usedAccessCode: null,
        // environment: null,
        firebaseMessagingTokens: null,
      };
      sessionStorage.setItem("token", JSON.stringify(authResult.user));
      setPending(true);
      setUpdatePending(true);
      createDoc("User", docId, userData, setPending, setStatus, setError);
    } else {
      sessionStorage.setItem("token", JSON.stringify(authResult.user));
      // var user = JSON.parse(sessionStorage.getItem("token"));
      if (!navigateFlag) {
        navigateFlag = true;
        navigate(location.state && location.state.fromInside ? -1 : "/", {
          state: location.state,
        });
      }
    }
  }

  async function handleAppleSignIn(event) {
    const AppleAuthProvider = new OAuthProvider("apple.com");
    AppleAuthProvider.addScope("email");
    AppleAuthProvider.addScope("name");
    AppleAuthProvider.setCustomParameters({
      locale: "en",
    });

    setPending(true);

    signInWithPopup(auth, AppleAuthProvider)
      .then((result) => {
        // The signed-in user info.
        const user = result.user;

        // Apple credential
        const credential = OAuthProvider.credentialFromResult(result);
        const accessToken = credential.accessToken;
        const idToken = credential.idToken;
        signInSuccess(result);

        setPending(false);

        // IdP data available using getAdditionalUserInfo(result)
        // ...
      })
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.customData.email;
        // The credential that was used.
        const credential = OAuthProvider.credentialFromError(error);

        setPending(false);

        // ...
      });
  }

  function handleTwitchSignIn(event) {
    setPending(true);
    AuthenticateWithTwitch(setPending, setError, setStatus, signInSuccess);
  }

  function setPageLayout() {
    if (pending) {
      return loading();
      // } else if (error) {
      // show error or do something else
      // return "error";
    } else {
      return (
        <div
          className="login col col-10 col-md-6 mx-auto"
          id="firebaseui-auth-container"
        >
          {/* twitch - apple */}
          <div className="firebaseui-container firebaseui-page-provider-sign-in firebaseui-id-page-provider-sign-in firebaseui-use-spinner">
            <div className="firebaseui-card-content">
              <form>
                <ul className="firebaseui-idp-list">
                  {/* twitch */}
                  <li className="firebaseui-list-item">
                    <button
                      className="firebaseui-idp-button mdl-button mdl-js-button mdl-button--raised firebaseui-id-idp-button"
                      style={{ backgroundColor: "#9146FF" }}
                      // style={{ backgroundColor: "#FFFFFF" }}
                      // data-upgraded=",MaterialButton"
                      onClick={handleTwitchSignIn}
                    >
                      <span className="firebaseui-idp-icon-wrapper">
                        <img
                          className="firebaseui-idp-icon"
                          alt=""
                          src={Icon_twitch}
                        ></img>
                      </span>
                      <span className="firebaseui-idp-text firebaseui-idp-text-long">
                        Sign in with Twitch
                      </span>
                      <span className="firebaseui-idp-text firebaseui-idp-text-short">
                        Twitch
                      </span>
                    </button>
                  </li>

                  {/* apple */}
                  <li className="firebaseui-list-item">
                    <button
                      className="firebaseui-idp-button mdl-button mdl-js-button mdl-button--raised firebaseui-id-idp-button custom"
                      style={{ backgroundColor: "#FFFFFF" }}
                      // style={{ backgroundColor: "#FFFFFF" }}
                      // data-upgraded=",MaterialButton"
                      onClick={handleAppleSignIn}
                    >
                      <span className="firebaseui-idp-icon-wrapper">
                        <img
                          className="firebaseui-idp-icon"
                          alt=""
                          src={Icon_apple_black}
                        ></img>
                      </span>
                      <span
                        className="firebaseui-idp-text firebaseui-idp-text-long"
                        style={{ color: "#757575" }}
                      >
                        Sign in with Apple
                      </span>
                      <span
                        className="firebaseui-idp-text firebaseui-idp-text-short"
                        style={{ color: "#757575" }}
                      >
                        Apple
                      </span>
                    </button>
                  </li>
                </ul>
              </form>
            </div>
          </div>

          {/* firebaseui providers hidden */}
        </div>
      );
    }
  }

  return setPageLayout();
};

export default Login;
