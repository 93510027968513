import React from 'react';
import {Image, Button} from "react-bootstrap";
import {isMobile} from "../utilities/Functions";
import AboutUsCategories from '../components/AboutUsCategories';
import AboutUsReviews from '../components/AboutUsReviews';
import AboutUsHowItWorks from '../components/AboutUsHowItWorks';
import AppStoreButtonLogo from "../assets/Download_on_the_App_Store_Badge_blk.svg";
import GooglePlayStoreButtonLogo from "../assets/GetItOnGooglePlay_Badge_Web_color_English.svg";
import FeaturedCreators from "../components/FeaturedCreators.js";


const AboutUs = () => {
  class Header extends React.Component {
    render() {
      return (
        <header className="App-header">
          <h1>Welcome to Rated10!</h1>
        </header>
      );
    }
  }
  
  class ProductDescription extends React.Component {
    render() {
      const containerStyle = {
        paddingBottom: isMobile() ? '20px' : '100px',
        paddingTop: isMobile() ? '20px' : '40px',
        paddingLeft: isMobile() ? '20px' : '40px',
        paddingRight: isMobile() ? '20px' : '40px',
        borderBottom: isMobile() ? '0px' : '1px solid #ddd',
        width: isMobile() ? '100%' : '66.67%', // 2/3 of the width
        color: 'black',
      };
  
      const wrapperStyle = {
        width: '100%',
      };
  
      const textStyle = {
        fontSize: isMobile() ? '1.2em' : '1.7em',
        textAlign: 'left',
        marginBottom: '1.7em',
      };
  
      const highlightedTextStyle = {
        fontSize: isMobile() ? '1.2em' : '1.7em',
        color: '#E35335',
        fontWeight: 'bold',
      };
  
      return (
        <section
          id="product-description"
          className="d-flex flex-column justify-content-center align-items-center"
          style={containerStyle}
        >
          <div style={wrapperStyle}> {/* Add a wrapper div to apply full width */}
            <h2 style={{ ...textStyle }}>
              Got a quick question for your favorite creator but unsure if you’ll get a response?
            </h2>
            <h2 style={{ ...textStyle, marginBottom: '3.4em' }}>
              <span style={highlightedTextStyle}>
                Rated10
              </span>
              {" "} {/* Add a space between the spans */}
              guarantees you’ll get an answer while supporting the creator you admire.
            </h2>
            <GetStartedButton />
          </div>
        </section>
      );
    }
  }  

  class GetStartedButton extends React.Component {
    scrollToBottom = () => {
      window.scrollTo({
        top: document.documentElement.scrollHeight,
        behavior: 'smooth'
      });
    };
  
    render() {
      if (isMobile()) {
        return (
          <Button
            variant="r10_orange text-white"
            className="mb-3 col-12 fw-bold d-flex align-items-center justify-content-center"
            style={{ height: "7vh" }}
            onClick={this.scrollToBottom}
          >
            Get Started
          </Button>
        );
      } else {
        return (
          <Button
            variant="r10_orange text-white"
            className="mb-3 col-4 fw-bold d-flex align-items-center justify-content-center"
            style={{ height: "7vh" }}
            onClick={this.scrollToBottom}
          >
            Get Started
          </Button>
        );
      }
    }
  }
    
  class ProductVideo extends React.Component {
    render() {
      return (
        <section
          id="product-video"
          className="d-flex flex-column justify-content-center align-items-center text-center"
          style={{
            paddingBottom: isMobile() ? '60px' : '100px',
            paddingTop: '20px',
            paddingLeft: isMobile() ? '20px' : '40px',
            paddingRight: isMobile() ? '20px' : '40px',
            borderBottom: '1px solid #ddd',
            width: isMobile() ? '100%' : '33.33%', // 1/3 of the width
          }}
        >
          <div style={{ width: '100%', maxWidth: '800px' }}>
            <div style={{ position: 'relative', width: '100%', height: 0, paddingBottom: '56.25%' }}>
              <iframe
                style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
                src="https://www.youtube.com/embed/KW1OIW71lZU"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="Product Video"
              ></iframe>
            </div>
          </div>
        </section>
      );
    }
  }

  class GetStarted extends React.Component {
    render() {
      const containerStyle = {
        display: 'flex',
        flexDirection: 'column',
        paddingBottom: isMobile() ? '60px' : '100px',
        paddingTop: '40px',
        paddingLeft: '0px',
        paddingRight: '0px',
        alignItems: 'flex-start',
        textAlign: 'left',
      };
  
      const buttonContainerStyle = {
        display: 'flex',
        gap: '20px',
        flexDirection: isMobile() ? 'column' : 'row',
      };
  
      const buttonStyle = {
        cursor: 'pointer',
        textDecoration: 'none',
        display: 'flex',
        alignItems: 'center',
        height: '63px',
        // backgroundColor: '#FFFFFF',
      };
  
      const webButtonStyle = {
        ...buttonStyle,
        padding: '10px 20px',
        border: '1px solid #000',
        borderRadius: '5px',
        backgroundColor: '#000000',
        color: '#FFFFFF',
        fontWeight: 'bold',
      };
  
      const appStoreButtonStyle = {
        ...buttonStyle,
        color: '#000000',
      };
  
      const googlePlayButtonStyle = {
        ...buttonStyle,
        color: '#FFFFFF',
      };

      const imageStyle = {
        height: '63px',
        marginRight: '10px',
      };

      const separatorStyle = {
        width: '100%',
        height: '1px',
        backgroundColor: '#ddd',
        marginBottom: '40px',
      };
  
      return (
        <div style={containerStyle}>
          <div style={separatorStyle}></div>
          <h2 style={{ fontSize: '2em', marginBottom: '40px' }}>Get Started</h2>
          <div style={buttonContainerStyle}>
            <a href="https://www.rated10.app/creators" style={webButtonStyle}>
              Get Started on Web
            </a>
            <a href="https://apps.apple.com/us/app/rated10/id6448967860" style={appStoreButtonStyle}>
            <Image src={AppStoreButtonLogo} alt="Apple Logo" style={imageStyle} />
            </a>
            <a href="https://play.google.com/store/apps/details?id=com.rated10.app&hl=en_US" style={googlePlayButtonStyle}>
              <Image src={GooglePlayStoreButtonLogo} alt="Google Play Logo" style={imageStyle} />
            </a>
          </div>
        </div>
      );
    }
  }

  function setPageLayout() {
    return (
      <div className="App">
        {/* <Header /> */}
        <div className="d-flex" style={{ display: 'flex', flexDirection: isMobile() ? 'column' : 'row', alignItems: 'stretch', justifyContent: 'center', width: '100%' }}>
          <ProductDescription />
          <ProductVideo />
        </div>
        <FeaturedCreators />
        <AboutUsHowItWorks />
        <AboutUsCategories />
        <AboutUsReviews />
        <GetStarted />
      </div>
    );
  }
  return setPageLayout();
};

export default AboutUs;
