import React, { useEffect, useState } from "react";
import { Button, Stack } from "react-bootstrap";
import {
  capitalizeFirstLetter,
  conversationTopics,
  dateDifferenceinDays,
  fbDateConvert,
  getFbDocs,
  getFbDocsWithQuery,
  getFbSingleDoc,
  loading,
  patchData,
  statusAlert,
} from "../utilities/Functions";
import "./PageStyles.css";
import { useGetDoc } from "../utilities/CustomHooks";
import { JobCompletionState } from "../utilities/Constants";
import { useNavigate } from "react-router-dom";
import ModalAlert from "../components/ModalAlert";

const PaymentHub = () => {
  var user = JSON.parse(sessionStorage.getItem("token"));
  var cachedUserInfo = JSON.parse(sessionStorage.getItem("userInfo"));

  const navigate = useNavigate();

  const [showAlert, setShowAlert] = useState();

  const [statusVariant, setStatusVariant] = useState();
  const [status, setStatus] = useState();

  const [pending, setPending] = useState(false);
  const [error, setError] = useState();

  const [updatePending, setUpdatePending] = useState();

  const { data: creatorInfo, setData: setCreatorInfo } = useGetDoc(
    "Creator",
    cachedUserInfo.asCreator ? cachedUserInfo.asCreator : null,
    [cachedUserInfo.asCreator],
    setPending,
    setError
  );

  const [paymentHistory, setPaymentHistory] = useState();

  const [earnedAmount, setEarnedAmount] = useState(0);
  const [completedInquiries, setCompletedInquiries] = useState([]);

  // dummy
  function setAsyncData() {}
  function setAsyncPending() {}
  function setAsyncError() {}
  function setAsyncStatus() {}
  // dummy

  // get payment details
  useEffect(() => {
    getPaymentDetails();
  }, [creatorInfo]);

  // // show alert for a time
  // useEffect(() => {
  //   if (statusVariant) {
  //     setTimeout(() => {
  //       setStatusVariant(null);
  //     }, 5000);
  //   }
  // }, [statusVariant]);

  // ****************************************
  // rest is function calls and return render
  // ****************************************

  function earningFormula(amount) {
    return (amount * 7) / 1000;
  }

  async function getPaymentDetails() {
    if (!creatorInfo) {
      return;
    }

    setPending(true);

    // payment history
    let paymentHistories = await getFbDocs(
      `Creator/${creatorInfo.id}/PaymentHistory`,
      setAsyncData,
      setAsyncPending,
      setAsyncError
    );
    setPaymentHistory(paymentHistories[paymentHistories.length - 1]);

    // one questions inquiries
    let { docs: questions, earnedAmount: questionsAmount } =
      await getInquiryAndPaymentInfo(
        "CompletedOneQuestionJob",
        creatorInfo.receivedOneQuestionSubmissionIds
      );

    // review inquiries
    let { docs: reviews, earnedAmount: reviewAmount } =
      await getInquiryAndPaymentInfo(
        "CompletedJob",
        creatorInfo.receivedSubmissionIds
      );

    let inquiries = questions.concat(reviews);

    inquiries.sort((a, b) =>
      dateDifferenceinDays(b.lastUpdateDate.toDate(), a.lastUpdateDate.toDate())
    );

    setEarnedAmount(questionsAmount + reviewAmount);
    setCompletedInquiries(inquiries);

    setPending(false);
  }

  async function getInquiryAndPaymentInfo(collectionId, inquiryList) {
    if (!inquiryList || inquiryList.length < 1) {
      return { docs: [], earnedAmount };
    }

    let queries = [
      {
        field: "id",
        condition: "in",
        value: inquiryList,
      },
      {
        field: "completionState",
        condition: "==",
        value: JobCompletionState.COMPLETED,
      },
    ];

    let docs = await getFbDocsWithQuery(
      collectionId,
      queries,
      setAsyncData,
      setAsyncPending,
      setAsyncError
    );
    if (!docs) {
      return { docs: [], earnedAmount };
    }

    let earnedAmount = 0;
    for (let i = 0; i < docs.length; i++) {
      earnedAmount = earnedAmount + Number(docs[i].amount);
      // let intents = await getFbDocs(
      //   `CompletedOneQuestionJob/${docs[i].id}/PaymentIntent`,
      //   setAsyncData,
      //   setAsyncPending,
      //   setAsyncError
      // );

      // if (!intents) {
      //   continue;
      // }

      // docs[i].earnedAmount = 0;
      // docs[i].intents = intents;
      // for (let j = 0; j < intents.length; j++) {
      //   docs[i].earnedAmount = docs[i].earnedAmount + intents[j].amount;
      //   earnedAmount = earnedAmount + intents[j].amount;
      // }
    }

    return { docs, earnedAmount };
  }

  async function stripePopUp(url) {
    return new Promise((resolve, reject) => {
      const popUpWindow = window.open(
        url,
        "_blank",
        "toolbar=yes,scrollbars=yes,resizable=yes,width=750,height=750"
        // "toolbar=yes,scrollbars=yes,resizable=yes"
      );

      var interval = setInterval(async () => {
        if (popUpWindow.closed) {
          resolve(true);
          clearInterval(interval);
        }
      }, 500);
    });
  }

  async function startEditPaymentAccountService() {
    // get stripe accounts and take last one
    let accounts = await getFbDocs(
      `Creator/${cachedUserInfo.asCreator}/StripeAccount`,
      setAsyncData,
      setAsyncPending,
      setAsyncError
    );
    if (accounts.length < 1) {
      // error handling - no account
      return;
    }
    let account = accounts[accounts.length - 1];
    let accountDocId = account.fbDocId;

    // set shouldEditAccountData true to start server service
    let data = { shouldEditAccountData: true };
    await patchData(
      `Creator/${cachedUserInfo.asCreator}/StripeAccount`,
      accountDocId,
      data,
      setAsyncPending,
      setAsyncStatus,
      setAsyncError
    );

    // listen this account and wait for new url
    let turnTimeOut = 0;
    // while (account.shouldEditAccountData && turnTimeOut < 20) {
    while (turnTimeOut < 20) {
      account = await getFbSingleDoc(
        `Creator/${cachedUserInfo.asCreator}/StripeAccount`,
        accountDocId,
        setAsyncData,
        setAsyncPending,
        setAsyncError
      );
      if (
        account &&
        !account.shouldEditAccountData &&
        !account.shouldFetchStripeAccountData
      ) {
        // show stripe link in pop up and wait it to be closed
        let isClosed = await stripePopUp(account.url);

        // update account data to start updating service
        data = { shouldFetchStripeAccountData: true };
        await patchData(
          `Creator/${cachedUserInfo.asCreator}/StripeAccount`,
          accountDocId,
          data,
          setAsyncPending,
          setAsyncStatus,
          setAsyncError
        );
        setUpdatePending(false);
        break;
      }
      setTimeout(() => {
        turnTimeOut++;
      }, 500);
    }
    if (turnTimeOut === 20) {
      // error handling - server service not worked
      return;
    }
  }

  async function startTransferToAccount() {
    // get transfer accounts and take first one
    let paymentHistoryArray = await getFbDocs(
      `Creator/${process.env.REACT_APP_TRANSFER_CREATOR}/PaymentHistory`,
      setAsyncData,
      setAsyncPending,
      setAsyncError
    );
    if (paymentHistoryArray.length < 1) {
      // error handling - no payment history record
      // alert(
      //   "Error at transferring the balance. Please try again later or contact support@rated10.app."
      // );
      setStatusVariant({
        message:
          "Error at transferring the balance. Please try again later or contact support@rated10.app.",
        variant: "warning",
      });
      setShowAlert(true);
      setUpdatePending(false);
      return;
    }
    let paymentHistory = paymentHistoryArray[0];
    let fbDocId = paymentHistory.fbDocId;

    // set shouldEditAccountData true to start server service
    let data = { shouldProcessTransfer: true };
    paymentHistory.shouldProcessTransfer = true;
    await patchData(
      `Creator/${process.env.REACT_APP_TRANSFER_CREATOR}/PaymentHistory`,
      fbDocId,
      data,
      setAsyncPending,
      setAsyncStatus,
      setAsyncError
    );

    // listen this account and wait for new url
    let turnTimeOut = 0;
    while (
      paymentHistory &&
      paymentHistory.shouldProcessTransfer &&
      turnTimeOut < 20
    ) {
      paymentHistory = await getFbSingleDoc(
        `Creator/${process.env.REACT_APP_TRANSFER_CREATOR}/PaymentHistory`,
        fbDocId,
        setAsyncData,
        setAsyncPending,
        setAsyncError
      );
      if (paymentHistory && !paymentHistory.shouldProcessTransfer) {
        break;
      }
      setTimeout(() => {
        turnTimeOut++;
      }, 500);
    }
    if (turnTimeOut === 20) {
      // error handling - server service not worked
      // alert(
      //   "Error at transferring the balance. Please try again later or contact support@rated10.app."
      // );
      setStatusVariant({
        message:
          "Error at transferring the balance. Please try again later or contact support@rated10.app.",
        variant: "warning",
      });
      setShowAlert(true);
      setUpdatePending(false);
      return;
    }

    if (paymentHistory.currentBalance === 0) {
      // success
      // alert("The balance is successfully transferred to your Stripe account.");
      setStatusVariant({
        message:
          "The balance is successfully transferred to your Stripe account.",
        variant: "success",
      });
      setShowAlert(true);
    } else {
      // error handling - currentBalance not 0 from server
      // alert(
      //   "Error at transferring the balance. Please try again later or contact support@rated10.app."
      // );
      setStatusVariant({
        message:
          "Error at transferring the balance. Please try again later or contact support@rated10.app.",
        variant: "warning",
      });
      setShowAlert(true);
    }

    setUpdatePending(false);
  }

  function handleTransfer(event) {
    setUpdatePending(true);
    startTransferToAccount();
  }

  function handleEdit(event) {
    setUpdatePending(true);
    startEditPaymentAccountService();
  }

  function handleSelectJobFromList(event, job) {
    var state = {
      userId: job.userId,
      creatorId: job.creatorId,
      service: {
        typeOfService: job.category,
        completionDeadline: job.completionDeadline,
        price: job.amount,
        tokenPrice: job.amountInTokens,
      },
      convoId: job.id,
      loggedBy: "creator",
    };
    navigate("/inquiry-detail-page", { state: state });
  }

  // questionCard - reviewCard - jobListLayout are copied from Inquiry Hub List
  function questionCard(job) {
    return (
      <div className="container p-2">
        <div className="row row-cols-2">
          <div className="col custom-fs-xs fw-bold text-truncate">
            {conversationTopics(job.category)}
          </div>
          <div className="col text-end custom-fs-xs">
            {job.completionState === JobCompletionState.ONGOING && (
              <span className="rounded-circle bg-r10_orange px-2"></span>
            )}
            {"Completed: " + fbDateConvert(job.lastUpdateDate)}
          </div>
          <div className="col custom-fs-xs text-truncate">{job.userName}</div>
          <div className="col text-end custom-fs-xs">
            {"Earning: " + earningFormula(job.amount) + " USD"}
          </div>

          <div className="col row-cols-2 ps-3 custom-fs-xs text-truncate">
            <span className="text-r10_orange">Q: </span>
            {job.question ? job.question : ""}
          </div>
          <div className="col"></div>

          <div className="col row-cols-2 ps-3 custom-fs-xs text-truncate">
            <span className="text-r10_orange">A: </span>
            {job.answer ? job.answer : ""}
          </div>
          <div className="col"></div>
        </div>
      </div>
    );
  }

  function reviewCard(job) {
    return (
      <div className="container p-2">
        <div className="row row-cols-2">
          <div className="col fw-bold custom-fs-xs text-truncate">
            {job.subject}
          </div>
          <div className="col text-end custom-fs-xs">
            {job.completionState === JobCompletionState.ONGOING && (
              <span className="rounded-circle bg-r10_orange px-2"></span>
            )}
            {"Completed: " + fbDateConvert(job.lastUpdateDate)}
          </div>
          <div className="col custom-fs-xs text-truncate">{job.userName}</div>
          <div className="col text-end custom-fs-xs">
            {"Earning: " + earningFormula(job.amount) + " USD"}
          </div>
        </div>
      </div>
    );
  }

  function jobListLayout() {
    return (
      // <div className="col-3 pe-3 d-flex flex-column h-100">
      <Stack id="sub_stack" gap={2} className="overflow-auto pe-3 text-start">
        {completedInquiries.map((job) => (
          <div
            key={`${job.id}`}
            className={"bg-r10_white rounded-3"}
            // role="button"
            // // onClick={(event) => handleSelectJobFromList(event, job)}
            // onDoubleClick={(event) => handleSelectJobFromList(event, job)}
          >
            {job.category.search("Question") > 0
              ? questionCard(job)
              : reviewCard(job)}
          </div>
        ))}
      </Stack>
      // </div>
    );
  }
  // questionCard - reviewCard - jobListLayout are copied from Inquiry Hub List

  function setPageLayout() {
    if (pending) {
      // return loading();
      // } else if (statusVariant) {
      //   return statusAlert(statusVariant.message, statusVariant.variant);
    } else {
      return (
        <div className="one-screen col col-12 col-md-8 d-flex flex-column mx-auto p-1 text-center">
          <h3 className="fw-bolder mb-4">Payment Hub</h3>
          <h1 className="fw-bold mt-4 custom-fs-xl">
            {paymentHistory ? paymentHistory.currentBalance : 0}
            {/* {earningFormula(earnedAmount)} */}
            <b className="fs-6"> USD</b>
          </h1>
          <p className="fst-italic mb-4">Earned since last transfer</p>

          <Button
            className="mx-auto mb-3 col col-8 col-md-4 rounded-4"
            variant="r10_orange text-white"
            style={{ height: "7vh" }}
            onClick={handleTransfer}
            disabled={updatePending}
          >
            Start transfer to account
          </Button>

          <Button
            className="mx-auto mb-4 col col-8 col-md-4 rounded-4"
            variant="light text-r10_orange"
            onClick={handleEdit}
            disabled={updatePending}
          >
            Edit Payment Account
          </Button>
          <hr className="me-3" />
          <h6 className="fw-bold text-start">Earnings History</h6>
          {jobListLayout()}
          {showAlert && (
            <ModalAlert
              message={statusVariant.message}
              variant={statusVariant.variant}
              showAlert={showAlert}
              setShowAlert={setShowAlert}
            />
          )}
        </div>
      );
    }
  }

  return setPageLayout();
};

export default PaymentHub;
