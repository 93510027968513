import "./MyNavbar.css";
import { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Form from "react-bootstrap/Form";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../utilities/Firebase";
import { Image, NavbarText } from "react-bootstrap";

import Logo from "../assets/Logo.png";
// import Avatar from "../assets/Avatar.png";
import Avatar from "../assets/Icon_profile.svg";
import Notification from "../assets/Icon_notification.svg";
import { cacheUtility, navbarUserCreatorCache } from "../utilities/Functions";

const MyNavbar = ({ setPageKey, searchParam, setSearchParam }) => {
  const [authenticatedUser, setAuthenticatedUser] = useState(null);
  const [userInfo, setUserInfo] = useState(
    JSON.parse(sessionStorage.getItem("userInfo"))
  );

  const [hideSearch, setHideSearch] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();



  // const [searchParam, setSearchParam] = useState("");

  // authentication change listener
  useEffect(() => {
    const listenAuth = onAuthStateChanged(auth, (user) => {
      if (user) {
        setAuthenticatedUser(user);

        // check user stored in session, if not store it
        navbarUserCreatorCache(user);
      } else {
        setAuthenticatedUser(null);
        setUserInfo(null);
      }
    });
    return () => {
      listenAuth();
    };
  }, []);

  // userInfo event listener for navigation elements show/hide
  useEffect(() => {
    if (!userInfo) {
      window.addEventListener("userInfo", () => {
        setUserInfo(JSON.parse(sessionStorage.getItem("userInfo")));
      });
    }
  }, []);

  // searchbar show/hide (only shown in creators pages)
  useEffect(() => {
    if (
      location.pathname !== "/" &&
      location.pathname !== "/all-creators" &&
      location.pathname !== "/creators"
    ) {
      setHideSearch(true);
      setSearchParam("");
    } else {
      setHideSearch(false);
    }
  }, [location.pathname]);

  // authentication change listener
  useEffect(() => {
    const listenAuth = onAuthStateChanged(auth, (user) => {
      if (user) {
        setAuthenticatedUser(user);

        // check user stored in session, if not store it
        navbarUserCreatorCache(user);
      } else {
        setAuthenticatedUser(null);
      }
    });
    return () => {
      listenAuth();
    };
  }, []);

  // mandatory cache stuff
  useEffect(() => {
    cacheUtility();
  }, []);

  // ****************************************
  // rest is function calls and return render
  // ****************************************

  function handleSearchEnter(event) {
    event.preventDefault();
    navigate("/all-creators");
  }

  const authButtons = () => {
    if (authenticatedUser === null) {
      return (
        // <ButtonGroup>
        <Nav className="me-auto custom-nav">
          <Nav.Link as={NavLink} to="/login">
            Login/Signup
          </Nav.Link>
          {/* <NavbarText className="me-auto custom-nav">/</NavbarText>
          <Nav.Link as={NavLink} to="/signup">
            Signup
          </Nav.Link> */}
          {/* </ButtonGroup> */}
        </Nav>
      );
    } else {
      return (
        <Nav className="me-auto">
          {/* <Nav.Link as={NavLink} to="/notification">
            <Image
              className="custom-icons"
              src={Notification}
              alt="Notifications"
            />
          </Nav.Link> */}
          <Nav.Link as={NavLink} to="/user-settings">
            <Image className="custom-icons" src={Avatar} alt="Settings" />
          </Nav.Link>
          {/* </ButtonGroup> */}
        </Nav>
      );
    }
  };

  return (
    <Navbar expand="lg" className="p-4 custom-navbar" sticky="top">
      {/* <Navbar expand="lg" className=""> */}
      <Container>
        <NavLink to="/">
          <Navbar.Brand>
            <Image src={Logo} alt="Rated10" className="logo" />
          </Navbar.Brand>
        </NavLink>
        <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={() => {
          document.body.scrollTop = document.documentElement.scrollTop = 0;
          }}/>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto custom-nav">
            <Nav.Link as={NavLink} to="/about-us">
              About Us
            </Nav.Link>
            <Nav.Link as={NavLink} to="/creators">
              Creators
            </Nav.Link>
            {authenticatedUser && (
              <Nav.Link
                as={NavLink}
                to="/inquiries"
                onClick={() => setPageKey((prev) => prev + 1)}
              >
                Inquiries
              </Nav.Link>
            )}
            {userInfo && userInfo.asCreator && (
              <Nav.Link
                as={NavLink}
                to="/creator-hub"
                onClick={() => setPageKey((prev) => prev + 1)}
              >
                Creator Hub
              </Nav.Link>
            )}
            <Nav.Link as={NavLink} to="/contact-us">
              Contact Us
            </Nav.Link>
            {/* <Nav.Link href="#home">Home</Nav.Link>
            <Nav.Link href="#link">Link</Nav.Link> */}
          </Nav>
          <Form
            className="d-flex col-xs-12 col-sm-10 col-md-8 col-lg-5"
            onSubmit={handleSearchEnter}
          >
            <Form.Control
              type="search"
              placeholder="Search for a creator"
              className="me-2"
              aria-label="Search"
              text-decoration="none"
              value={searchParam}
              hidden={hideSearch}
              onChange={(event) => {
                setSearchParam(event.target.value);
              }}
              // color="#b2b2b2"
              // border-radius="10px"
              // padding-left="1rem"
            />
          </Form>
          <Form inline>{authButtons()}</Form>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default MyNavbar;
